const API_URL = {
  monoUrl:
    process.env.NODE_ENV === 'development'
      ? 'https://mono.sandbox.abansite.com/panel-admin/'
      : 'https://mono.abanchange.me/panel-admin/',
  microUrl:
    process.env.NODE_ENV === 'development'
      ? 'https://api.sandbox.abansite.com/admin/'
      : 'https://api.abanchange.me/admin/',
  gneralUrl:
    process.env.NODE_ENV === 'development'
      ? 'https://api.sandbox.abansite.com/'
      : 'https://api.abantether.com/',
}

export default { API_URL }
