import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import axios from 'axios'
import VueAxios from 'vue-axios'

import VueCookies from 'vue3-cookies'

import settings from './settings'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { Editor } from '@tinymce/tinymce-vue/lib/cjs/main/ts/components/Editor'
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'
import VueScrollingTable from 'vue-scrolling-table'
import '/node_modules/vue-scrolling-table/dist/style.css'
import Notifications from '@kyvg/vue3-notification'
import { notify } from '@kyvg/vue3-notification'
import ECharts from 'vue-echarts'
import { microServiceApi } from '@/boot/axios'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Notifications)
app.component('v-chart', ECharts)
app.component('DatePicker', Vue3PersianDatetimePicker)
app.component('Editor', Editor)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('v-select', vSelect)
app.component(VueScrollingTable.name, VueScrollingTable)
app.use(VueAxios, axios)
app.use(VueCookies)
app.axios.defaults.baseURL = settings.API_URL.monoUrl
app.mount('#app')
app.axios.defaults.withCredentials = true

router.beforeEach((to, from, next) => {
  if (store.state.token == '') {
    if (to.name == 'Login') {
      next()
    } else {
      axios
        .post(`${microServiceApi}auths/tokens/access`, {})
        .then((response) => {
          store.commit({
            type: 'setToken',
            value: response.data.data.access_token,
          })
          next()
        })
        .catch(() => {
          axios.post('/users/logout/', {}).finally(() => {
            store.commit({
              type: 'setToken',
              value: '',
            })
            next({ name: 'Login' })
          })
        })
    }
  } else {
    next()
  }
})

axios.interceptors.request.use(
  (config) => {
    config.headers['Accept-Language'] = 'fa'
    let token = store.state.token
    if (token != '') {
      config.headers['Authorization'] = token
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const responseURL =
      error.response && error.response.request
        ? error.response.request.responseURL || ''
        : ''
    const errorStatus = error && error.response ? error.response.status : ''
    if (errorStatus === 401) {
      if (responseURL.includes('logout')) {
        store.commit({
          type: 'setToken',
          value: '',
        })
        router.push({ name: 'Login' })
      } else {
        return axios
          .post(`${microServiceApi}auths/tokens/access`, {})
          .then((response) => {
            store.commit({
              type: 'setToken',
              value: response.data.data.access_token,
            })

            let access_token =
              response.data && response.data.data
                ? response.data.data.access_token
                : ''
            error.config.headers['Authorization'] = 'Bearer ' + access_token
            return axios.request(error.config)
          })
          .catch(() => {
            axios.post('/users/logout/', {}).finally(() => {
              store.commit({
                type: 'setToken',
                value: '',
              })
              router.push({ name: 'Login' })
            })
          })
      }
    } else if (errorStatus === 500) {
      notify({
        text: 'سرور دچار مشکل شده است.',
        type: 'error',
      })
    } else if (errorStatus === 403) {
      notify({
        text: 'شما دسترسی لازم را ندارید.',
        type: 'error',
      })
    } else if (errorStatus === 400) {
      let er = ''
      for (const key in error.response.data.errors) {
        er += `${key}: ${error.response.data.errors[key]} \n`
      }
      notify({
        title: error.response.data.message,
        text: er,
        type: 'error',
      })
    }
    return Promise.reject(error)
  },
)

app.config.globalProperties.$filters = {
  persianToEnglishDigits(txt) {
    if (!(typeof txt == 'string')) return txt
    let persian_digits_map = {
      '۱': '1',
      '۲': '2',
      '۳': '3',
      '۴': '4',
      '۵': '5',
      '۶': '6',
      '۷': '7',
      '۸': '8',
      '۹': '9',
      '۰': '0',
    }

    let new_txt = ''

    for (let c of txt) {
      if (c in persian_digits_map) new_txt = new_txt + persian_digits_map[c]
      else new_txt = new_txt + c
    }

    return new_txt
  },
  formatPhoneNumber(phoneNumber) {
    if (phoneNumber == null) return null

    return phoneNumber.replace('+98', '0')
  },
  prettyPositiveInteger(number) {
    number = number.toString()
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  prettyInteger(number) {
    number = number.toString()
    if (number.includes('-')) {
      number = number.replace('-', '')
      return (
        '-' + app.config.globalProperties.$filters.prettyPositiveInteger(number)
      )
    }
    return app.config.globalProperties.$filters.prettyPositiveInteger(number)
  },
  prettyNumber(number) {
    number = number.toString()
    if (number.includes('.')) {
      var parts = number.toString().split('.')
      parts[0] = app.config.globalProperties.$filters.prettyInteger(parts[0])
      return parts.join('.')
    }
    return app.config.globalProperties.$filters.prettyInteger(number)
  },
  formatDecimal(decimal, digitAfterDecimal = 8) {
    if (!decimal && decimal !== 0) return ''
    if (decimal === null || decimal === '') return ''
    return Number(parseFloat(decimal).toFixed(digitAfterDecimal))
  },
  formatNumberString(numberStr, allowedDecimals = 20) {
    if (!numberStr.includes('.')) return numberStr

    let decimalPlaces = numberStr.length - numberStr.indexOf('.') - 1
    if (decimalPlaces > allowedDecimals)
      return numberStr.slice(0, -(decimalPlaces - allowedDecimals))
    return numberStr
  },
  numberToString(number) {
    let newValue = number.toString()
    if (newValue.includes('e')) {
      let pw = -Number(newValue.split('e')[1])
      let meanPart = newValue.split('e')[0]
      newValue = this.move(meanPart, pw)
    }
    return newValue
  },
  move(number, position) {
    let res = ''
    if (position > 0) {
      res = this.moveRight(number, position)
    } else res = this.moveLeft(number, -position)

    while (res.includes('.') && (res.slice(-1) == '0' || res.slice(-1) == '.'))
      res = res.slice(0, -1)

    return res
  },
  moveRight(number, position) {
    if (!number.includes('.')) number = number + '.0'

    while (position) {
      let pointPosition = number.indexOf('.')
      let next = number[pointPosition - 1]
      number = this.replaceAt(number, pointPosition, next)
      number = this.replaceAt(number, pointPosition - 1, '.')

      if (number[0] == '.') number = '0' + number
      position -= 1
    }

    return number
  },
  moveLeft(number, position) {
    if (!number.includes('.')) number = number + '.0'

    while (position) {
      let pointPosition = number.indexOf('.')
      let next = number[pointPosition + 1]
      number = this.replaceAt(number, pointPosition, next)
      number = this.replaceAt(number, pointPosition + 1, '.')
      if (number.slice(-1) == '.') number = number + '0'
      position -= 1
    }
    return number
  },
  replaceAt(str, index, replacement) {
    return (
      str.substring(0, index) +
      replacement +
      str.substring(index + replacement.length)
    )
  },
  removeDecimalPart(decimal) {
    return Math.trunc(decimal)
  },
  formatDatetime(datetime) {
    var moment = require('moment-jalaali')
    return moment(datetime).format('jYYYY/jM/jD HH:mm:ss')
  },
}
