<template>
  <div class="flex-grow-1 d-flex flex-row justify-content-center">
    <div class="relative-position pb-2" style="width: 844px">
      <div
        class="d-flex flex-row justify-content-center gap-2 mx-auto"
        style="width: 820px"
      >
        <CFormSelect
          style="min-width: 200px; max-width: 200px"
          v-model="search_type"
          :options="[
            {
              value: 'phone_nationalcode',
              label: 'کد ملی، شماره موبایل',
            },
            {
              value: 'email_name',
              label: 'نام و ایمیل',
            },
          ]"
        />
        <CFormInput
          style="min-width: 300px; max-width: 300px"
          v-model="search_text"
          :placeholder="
            search_type === 'phone_nationalcode'
              ? 'کد ملی یا شماره موبایل مورد جستجو را وارد...'
              : 'نام کاربر یا ایمیل مورد جستجو را وارد...'
          "
          @keypress="handleSearchInputEnter"
        >
        </CFormInput>

        <CButton
          color="primary"
          style="min-width: 160px; max-width: 160px; border-radius: 50px"
          @click="searchUsers"
          :disabled="exactSearchLoading"
        >
          <span v-if="!quickSearchLoading">جستجو سریع</span>
          <div
            class="position-relative"
            v-if="quickSearchLoading"
            style="height: 20px"
          >
            <CSpinner color="warning" style="width: 20px; height: 20px" />
          </div>
        </CButton>
        <CButton
          color="primary"
          variant="outline"
          style="min-width: 160px; max-width: 160px; border-radius: 50px"
          @click="exactSearchUsers"
          :disabled="quickSearchLoading"
        >
          <span v-if="!exactSearchLoading">جستجو دقیق</span>
          <div
            class="position-relative"
            v-if="exactSearchLoading"
            style="height: 20px"
          >
            <CSpinner color="warning" style="width: 20px; height: 20px" />
          </div>
        </CButton>
      </div>

      <!-- backdrop -->
      <div
        v-if="resultModalShown && !isInUserSearchPage"
        class="position-absolute"
        style="
          background-color: #9d9d9d40;
          width: 100%;
          height: 100vh;
          left: 0;
          top: 0;
          cursor: pointer;
        "
        @click="reset"
      ></div>
      <div
        class="position-absolute p-3 shadow"
        v-if="
          resultModalShown &&
          users &&
          users.length > 0 &&
          !quickSearchLoading &&
          !exactSearchLoading &&
          !isInUserSearchPage
        "
        style="
          background-color: white;
          width: 844px;
          border-radius: 10px;
          height: 600px;
          overflow-y: scroll;
        "
      >
        <div class="text-end border-bottom">
          <CButton @click="reset" class="p-0">
            <CIcon name="cil-x-circle"> </CIcon>
          </CButton>
        </div>
        <CButton
          class="mb-2 w-100"
          color="dark"
          variant="outline"
          v-for="(user, index) in users"
          :key="index"
          @click="() => showUserDetail(user.id)"
        >
          <div
            class="d-flex flex-row justify-content-between align-items-center"
          >
            <div>
              {{ user.fullName }}
            </div>
            <div class="text-end">
              <div class="mb-2" dir="ltr">
                {{ user.phoneNumber }}
              </div>
              <div>{{ user.nationalNumber }}</div>
            </div>
          </div>
        </CButton>
      </div>
      <div
        class="position-absolute p-3 shadow"
        v-if="
          resultModalShown &&
          (!users || users.length === 0) &&
          !quickSearchLoading &&
          !exactSearchLoading &&
          !isInUserSearchPage
        "
        style="background-color: white; width: 844px; border-radius: 10px"
      >
        جستجو نتیجه‌ای به همراه نداشت. با عبارات دیگر تلاش کنید.
      </div>
    </div>
  </div>
</template>

<script>
import { notify } from '@kyvg/vue3-notification'
export default {
  name: 'UserSearch',
  data() {
    return {
      users: [],
      search_text: '',
      search_type: 'phone_nationalcode',
      selectedUserId: '',
      resultModalShown: false,
      quickSearchLoading: false,
      exactSearchLoading: false,
    }
  },
  created() {},
  computed: {
    isInUserSearchPage() {
      return this.$route.fullPath === '/user-search'
    },
  },
  methods: {
    searchUsers() {
      if (!this.search_text) {
        notify({
          text: 'عبارت جستجو نمی‌تواند خالی باشد',
          type: 'error',
        })
        return
      }
      this.$store.commit({ type: 'setSelectedUserId', value: '' }) // reseting selected uset which must be shown in user-search page.
      this.quickSearchLoading = true
      this.resultModalShown = true
      this.axios
        .get(
          '/users/customer/search/?text=' +
            this.search_text +
            '&search_type=' +
            this.search_type,
        )
        .then((response) => {
          this.users = response.data
          for (let user of this.users) {
            user.phoneNumber = user.phoneNumber
              ? user.phoneNumber.replace('+98', '0')
              : ''
          }
          this.$store.commit({ type: 'setUserSearchResult', value: this.users })
        })
        .finally(() => {
          this.quickSearchLoading = false
        })
    },
    exactSearchUsers() {
      if (!this.search_text) {
        notify({
          text: 'عبارت جستجو نمی‌تواند خالی باشد',
          type: 'error',
        })
        return
      }
      this.exactSearchLoading = true
      this.resultModalShown = true
      this.axios
        .get(
          '/users/customer/lookup/?text=' +
            this.search_text +
            '&search_type=' +
            this.search_type,
        )
        .then((response) => {
          this.users = response.data
          for (let user of this.users) {
            user.phoneNumber = user.phoneNumber
              ? user.phoneNumber.replace('+98', '0')
              : ''
          }
          this.$store.commit({ type: 'setUserSearchResult', value: this.users })
        })
        .finally(() => {
          this.exactSearchLoading = false
        })
    },
    showUserDetail(id) {
      this.resultModalShown = false
      this.$store.commit({ type: 'setSelectedUserId', value: id })
      this.$router.push('/user-search')
    },
    reset() {
      this.users = []
      this.resultModalShown = false
      this.$store.commit({ type: 'setUserSearchResult', value: this.users })
    },
    handleSearchInputEnter(event) {
      if (event && event.key === 'Enter') {
        this.searchUsers()
      }
    },
  },
}
</script>

<style>
.modal-header .btn-close {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
