<template>
  <CFooter>
    <div>
      <a href="https://abantether.com/" target="_blank">آبان تتر</a>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }} </span>
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank"> قدرت گرفته از</span>
      <a href="https://abantether.com/">CoreUI for Vue</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
