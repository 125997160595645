import { createStore } from 'vuex'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    token: '',
    userSearch: {
      searchResult: [],
      selectedUserId: '',
    },
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setToken(state, payload) {
      state.token = payload.value
    },
    setUserSearchResult(state, payload) {
      state.userSearch.searchResult = payload.value
    },
    setSelectedUserId(state, payload) {
      state.userSearch.selectedUserId = payload.value
    },
  },
  actions: {},
  modules: {},
})
